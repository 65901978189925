/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { createRef, useCallback, useEffect, useState } from 'react'
import classes from '../NewReservation.module.css'
import WizardStep from '../../../../components/common/wizard/WizardStep';
import { Formik } from 'formik';
import { NewReserveDTO } from '../../../../model/dto-module';
import { CodeDescription } from '../../../../model/models-module';
import { FormControl, MenuItem, Select } from '@mui/material';
import StockService from '../../../../services/StockService';
import { toast } from 'react-toastify';
import InputFilter from '../../../../components/common/input-filter/InputFilter';

type Props = {
  value: NewReserveDTO;
  fixedSKU: boolean;
  isEdit: boolean;
  showSkuDesc?: boolean;
  distributionCentres: CodeDescription[];
  reserveTypes: CodeDescription[];
  onAfterSubmit(values: any): void; 
  onAfterBack(): void; 
}

const NewReserveStepOne = ({value, fixedSKU = false, isEdit, showSkuDesc = false, distributionCentres, reserveTypes, onAfterSubmit, onAfterBack}: Props) =>{
  const refSubmit = createRef<HTMLButtonElement>();
  const [service] = useState<StockService>(StockService.Instance);
  const [formValue, setFormValue] = useState<NewReserveDTO>(value);
  const [skuDesc, setSkuDesc] = useState<string|undefined>(value.productDesc);

  const validateSku = useCallback((pDistributionCode: string, pProductCode: string)=>{
      if(pDistributionCode && pProductCode && pProductCode.length > 3) {
        service.stockAvailable(pDistributionCode, pProductCode)
        .then(
          (resp)=> {
            if(resp == undefined || resp === null) {
              setSkuDesc('');
              toast.error('El artículo no está disponible para el centro de distribución');              
            } else {
              setSkuDesc(resp.description);
            }
          }
        ).catch(e=>{
          console.error(e);
          setSkuDesc('');
        })
      }
  }, []);

  useEffect(()=>{
    if(value.reserveTypeCode) {
      console.warn('entra a validar el reserveTypeCode', reserveTypes,  value.reserveTypeCode)
      value.reserveType = reserveTypes.find((rt)=>rt.code === value.reserveTypeCode);
      setFormValue(new NewReserveDTO(value));
    }
    if(showSkuDesc) {
      validateSku(value.distributionCentreCode ?? '', value.productCode ?? '');
    }

  }, [reserveTypes, setFormValue, validateSku]);


    return (
      <WizardStep btnCancelDisabled={false} cancelBackBtnLbl='Cancelar' onNext={()=> { if(refSubmit && refSubmit.current) refSubmit.current.click()}} onBack={()=>{onAfterBack()}} btnBackDisabled={true} btnNextDisabled={false} btnEndDisabled={true}>

      <Formik
        initialValues={formValue}
        validate={values => {
          
          const errors: any = {};
          if(values.distributionCentreCode == undefined) {
            errors.distributionCentreCode = 'El centro de distribucion es obligatorio.';
          }
          if(values.reserveTypeCode == undefined) {
            errors.reserveTypeCode = 'El tipo de reserva es obligatorio.';
          }
          if(values.stockReserved == undefined) {
            errors.stockReserved = 'La cantidad es obligatoria.';
          }
          if(values.productCode == undefined) {
            errors.productCode = 'El sku es obligatorio.';
          }

          return errors;
        }}
        onSubmit={(values, { setSubmitting }) => {
          values.productDesc = skuDesc;
          console.warn('Valores a submitear: ', values);
          onAfterSubmit(values);
          setSubmitting(false);
        }}
      >
        {({
          handleSubmit,
          handleChange,
          setFieldValue,
          isSubmitting,
          values,
          errors,
          touched,
          /* and other goodies */
        }) => (

          <form onSubmit={handleSubmit}>
            <div className={`${classes.formContainer} nwsn-scroll`}>
                <div className={classes.webContainer}>
                  <div className={classes.fieldContainer}>
                    <div>
                      <label htmlFor="distributionCentreCode" className={classes.required}>Centro de distribución</label>
                      <FormControl key={'select-centre'} disabled={isEdit}
                       className={`${classes['form-select-opt']} ${errors.stockReserved && touched.stockReserved ? classes.error : ''}`} size="small">
                        <Select defaultValue={value.distributionCentreCode} onChange={(e)=>{
                          setFieldValue('distributionCentreCode', e.target.value);
                          validateSku( e.target.value, values.productCode ?? '');
                        }
                          }>
                            {
                              (distributionCentres ?? []).map((d, i)=><MenuItem className='select-option' key={`opt-centre-${i}`} value={d.code}>{d.code}</MenuItem>)
                            }
                        </Select>
                      </FormControl>
                      { 
                        errors.stockReserved && touched.stockReserved ? 
                        <span className={classes.errorMsg}>{errors.distributionCentreCode && touched.distributionCentreCode && errors.distributionCentreCode}</span>
                        : null
                      }
                    </div>
                  </div>
                  <div className={classes.fieldContainer}>
                      <div>
                        <label htmlFor="productCode" className={classes.required}>SKU</label>
                        <InputFilter inputDisabled={fixedSKU} debounceTime={800} cssClass={`${classes.formField} ${errors.productCode && touched.productCode ? classes.error : ''}`}
                        value={values.productCode} onChange={(e)=>{ validateSku(values.distributionCentreCode ?? '', e ?? ''); setFieldValue('productCode', e); }}></InputFilter>
                        { errors.productCode && touched.productCode ? <span className={classes.errorMsg}>{errors.productCode && touched.productCode && errors.productCode}</span> : null}
                      </div>
                  </div>
                  {
                    showSkuDesc && skuDesc! ?
                    <div className={classes.fieldContainer}>
                      <span>{skuDesc}</span>
                    </div>
                    : null
                  }
                  <div className={classes.fieldContainer}>
                    <div>
                      <label htmlFor="reserveType" className={classes.required}>Tipo de reserva</label>
                      <FormControl key={'select-centre'} className={`${classes['form-select-opt']} ${errors.reserveTypeCode && touched.reserveTypeCode ? classes.error : ''}`}
                       size="small" disabled={isEdit}>
                        <Select defaultValue={value.reserveTypeCode} onChange={(e)=>{
                          setFieldValue('reserveTypeCode', e.target.value);
                          setFieldValue('reserveType', reserveTypes.find((rt)=>rt.code === e.target.value));
                        }
                          }>
                            {
                              (reserveTypes ?? []).map((d, i)=><MenuItem className='select-option' key={`opt-centre-${i}`} value={d.code}>{d.description}</MenuItem>)
                            }
                        </Select>
                      </FormControl>
                      { errors.reserveTypeCode && touched.reserveTypeCode ? 
                      <span className={classes.errorMsg}>{errors.reserveTypeCode && touched.reserveTypeCode && errors.reserveTypeCode}</span>
                    : null}
                    </div>
                  </div>
                  <div className={classes.fieldContainer}>
                      <div>
                        <label htmlFor="stockReserved" className={classes.required}>Cantidad</label>
                        <input name='stockReserved' 
                        className={`${classes.formField} ${errors.stockReserved && touched.stockReserved ? classes.error : ''}`} onChange={handleChange} value={values.stockReserved} />
                        { errors.stockReserved && touched.stockReserved ? 
                        <span className={classes.errorMsg}>{errors.stockReserved && touched.stockReserved && errors.stockReserved}</span>
                        : null
                      }
                      </div>
                  </div>
                  
                </div>
              </div>

          <button type="submit" ref={refSubmit} disabled={isSubmitting} style={{display:'none'}}>
            Submit
          </button>
          </form>
        )}
      </Formik>
    </WizardStep>
            )
}


export default NewReserveStepOne;